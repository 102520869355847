import React, { useState, useEffect } from "react";
import { Seo, PageHero } from "../components";
import emailjs from "@emailjs/browser";
import { StaticImage } from "gatsby-plugin-image";
import tw, { styled } from "twin.macro";
import { BsCheckCircle, BsXCircle } from "react-icons/bs";
import { Link } from "gatsby";

const ContactWrapper = styled.div`
  ${tw`bg-secondary mx-auto max-w-[500px] p-6 rounded-2xl flex flex-col justify-center mb-8 shadow-three`}

  form {
    ${tw`flex flex-col gap-4 `}
  }

  div.form-group {
    ${tw`flex flex-col`}
  }

  .error-message {
    ${tw`text-sm pl-2 block`}
    color: var(--bc-error);
  }

  label {
    ${tw`font-bold mb-1`}
  }

  input[type="text"],
  input[type="email"],
  textarea {
    ${tw`p-2 rounded border-2 border-solid border-normal hover:border-active focus:border-active outline-none w-full bg-secondary text-primary`}
    font-family:'Cardo';
  }

  input.okay,
  textarea.okay {
    ${tw`border-okay`}
  }

  .input-group {
    ${tw`relative`}
  }

  svg {
    ${tw`absolute right-2 top-[11px] h-[18px] text-rblue`}
  }

  .textarea-group {
    ${tw`relative`}
  }

  .textarea-group > svg {
    ${tw`absolute right-2 h-[18px] text-rblue`}
    top: calc(100% - 34px)
  }
  svg.error {
    color: var(--bc-error);
  }

  input.error {
    ${tw`border-error pl-2`}
  }

  textarea {
    ${tw`w-full min-h-[240px] resize-y`}
  }

  button {
    ${tw`w-[200px] h-[40px] bg-active text-white outline-none rounded-lg border-none mx-auto mt-12 hover:cursor-pointer shadow-one active:shadow-sm transition duration-500 ease-in-out`}
  }

  button:hover {
    transform: scale(1.1);
  }

  button.disabled {
    ${tw`pointer-events-none cursor-not-allowed bg-primary shadow-none`}
  }

  h3,
  p,
  a {
    ${tw`w-full text-center whitespace-pre-wrap`}
  }

  p {
    ${tw`mt-[40px] mb-[164px]`}
  }
`;

const ContactPage = () => {
  const [messageSent, setMessageSent] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [emailError, setEmailError] = useState("");

  const clearForm = () => {
    setName("");
    setEmail("");
    setSubject("");
    setMessage("");
    setEmailError("");
  };

  const sendEmail = (e) => {
    e.preventDefault();
    const serviceId = process.env.EMAILJS_SERVICE_ID;
    const templateId = process.env.EMAILJS_TEMPLATE_ID;
    const userId = process.env.EMAILJS_USER_ID;

    const data = { name, email, subject, message };

    emailjs
      .send(serviceId, templateId, data, userId)
      .then(() => {
        setMessageSent(true);
      })
      .catch((err) => {
        setEmailError(
          "The email cannot be sent due to the high traffic volume. Please try again later."
        );
      });
  };

  const handleEmailChange = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
    const emailRe =
      /^(([^<>()[\].,;:\s@"]+(.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+.)+[^<>()[\].,;:\s@"]{2,})$/i;
    new RegExp(emailRe).test(email)
      ? setEmailError("")
      : setEmailError("Email address is in an incorrect format.");

    if (e.target.value.length === 0) {
      setEmailError("");
    }
  };

  const enabledButton = name && email && subject && message && !emailError;

  const ContactForm = (
    <>
      <form onSubmit={sendEmail} autoComplete="off" id="contactForm">
        <div className="form-group">
          <label htmlFor="contact_name">Name</label>
          <div className="input-group">
            <input
              type="text"
              name="name"
              id="contact_name"
              placeholder="John Doe"
              onChange={(e) => setName(e.target.value)}
              className={name ? "okay" : ""}
            />
            {name ? <BsCheckCircle /> : ""}
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="contact_email">Email</label>
          <div className="input-group">
            <input
              type="email"
              name="email"
              id="contact_email"
              placeholder="john.doe@example.com"
              onChange={handleEmailChange}
              className={email ? (emailError ? "error" : "okay") : ""}
            />
            {email ? (
              emailError ? (
                <BsXCircle className="error" />
              ) : (
                <BsCheckCircle />
              )
            ) : (
              ""
            )}
          </div>
          {emailError ? (
            <div className="error-message">&#x2715; {emailError}</div>
          ) : (
            ""
          )}
        </div>

        <div className="form-group">
          <label htmlFor="contact_subject">Subject</label>
          <div className="input-group">
            <input
              type="text"
              name="subject"
              id="contact_subject"
              placeholder="1 on 1 booking"
              onChange={(e) => setSubject(e.target.value)}
              className={subject ? "okay" : ""}
            />
            {subject ? <BsCheckCircle /> : ""}
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="contact_message">Message</label>
          <div className="textarea-group">
            <textarea
              name="message"
              id="contact_message"
              placeholder="Hello, I'd like to schedule a 1 on 1 discussion so that I can go over my..."
              onChange={(e) => setMessage(e.target.value)}
              className={message ? "okay" : ""}
            />
            {message ? <BsCheckCircle /> : ""}
          </div>
        </div>
      </form>
      <button
        form="contactForm"
        type="submit"
        value="Submit"
        className={enabledButton ? "" : "disabled"}
      >
        Submit
      </button>
    </>
  );

  const SuccessfulNotification = (
    <>
      <h3>Your message has been sent successfully!</h3>
      <p>
        Thank you for reaching out to us! A representative from the Young
        Disciple Fellowship will write back soon.
      </p>
      <Link to="/">
        <button>Back to Home Page</button>
      </Link>
    </>
  );

  // clear form before component will unmount
  useEffect(() => {
    return () => {
      clearForm();
    };
  }, [messageSent]);

  return (
    <>
      <Seo
        title="Contact"
        description="Feel free to write a message using the form below with any issues."
      />
      <PageHero
        img={
          <StaticImage
            src={"../images/omar-flores-4X3DCszh_v0-unsplash.jpg"}
            alt="Sitting in the back of a open study space in a library."
            placeholder="blurred"
            layout="constrained"
            width={3840}
            height={2160}
            loading="eager"
            className="hero-img"
          />
        }
        title="Contact"
        text="Feel free to write a message using the form below with any issues."
      />
      <div className="page-main">
        <ContactWrapper>
          {messageSent ? SuccessfulNotification : ContactForm}
        </ContactWrapper>
      </div>
    </>
  );
};

export default ContactPage;
